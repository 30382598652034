import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog, DialogContent, IconButton, InputBase } from '@mui/material';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import TimelineIcon from '@mui/icons-material/Timeline';

import 'assets/scss/index.scss';
import v from 'assets/scss/_variables.scss';
import scss from 'views/protein/ProteinSelection.module.scss';
import scssCommon from 'assets/scss/common.scss';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText } from 'components/tag/Text';
import { MuiTableCell, TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import { spp } from 'utils/network/jsons';
import 'utils/string';
import links from 'utils/links';
import { RouterLink } from 'components/router/RouterLink';
import { getFullPath, navigateNewTab } from 'utils/general';
import { useLocation } from 'react-router-dom';
import { getKeyByValue } from 'utils/object';
import { A } from 'components/tag/A';

//---------------------------------------------------------------------------
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props} />
))(({ theme }) => ({
  // figma Dropdown
  '& .MuiPaper-root': {

    boxSizing: 'border-box',
    // display: inline-flex,
    padding: '8px 0px',
    // flex-direction: column,
    // align-items: flex-start,

    // width: '101px',
    // height: '144px',

    borderRadius: 8,
    border: `1px solid ${v.grey25}`,
    // border: `1px solid ${v.green}`, //debug
    background: v.white,

    /* Card - Drop Shadow */
    boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.10)',

    // figma 10 items
    '& .MuiMenu-list': {
      color: v.darkGreen,
    },

    // figma Frame 29731
    '& .MuiMenuItem-root': {
      justifyContent: 'right',
      // padding: '10px 16px',

      '&:active': {
        backgroundColor: v.green,
      },
    },

    '& .Mui-selected': {
      backgroundColor: v.grey25,
      // backgroundColor: v.green, //debug
    },
  },
}))

//---------------------------------------------------------------------------
const SearchResultTable = (props) => {
  const { tableBodyCell, inputProp } = props
  const isCount = (tableBodyCell === spp.protein.search.fragment_count.output)
    || (tableBodyCell === spp.protein.search.classify_count.output)
  const isPeptideList = (tableBodyCell === spp.protein.search.peptide_list.output)
  let isHideSearchBar = (tableBodyCell === spp.peptide.search.inductive_analysis.items)
    || (tableBodyCell === spp.peptide.search.inductive_analysis.items_ic50)
  let isHidePageButton = false
  const isPDCAASTable = (tableBodyCell === spp.tool.pdcaas_table.items)
  const isToolManualPeptide = (tableBodyCell === spp.tool.hot_spot_result_chart.items_adv)
  
  let tableHeadCell = []
  if (props.tableHeadCell === spp.protein.search.fragment_count.output_text
    || props.tableHeadCell === spp.protein.search.classify_count.output_text) {
    let head = props.items[props.info[0]]
    // console.log(head)
    let head1D = getArray1D(head)
    // console.log(head1D)
    let head1D4 = head1D.slice(4)
    let headFix = props.tableHeadCell.slice(0, 4 + 1)
    headFix = headFix.concat(head1D4)
    let index = headFix.indexOf('Database_reference')
    let length = props.tableHeadCell.length
    if (index !== -1)
      headFix[index] = props.tableHeadCell[length - 1] //'UniProtKB'

    tableHeadCell = headFix
    // console.log(headFix)
  } else {
    tableHeadCell = props.tableHeadCell
  }
  // console.log('tableHeadCell', tableHeadCell)

  let items = []
  if (props.tableBodyCell === spp.protein.search.fragment_count.output
    || props.tableBodyCell === spp.protein.search.classify_count.output) {
    let body = props.items[props.info[1]]
    // console.log('props.info', props.info)
    // console.log('props.items', props.items)
    console.log(body)
    if (body !== undefined) {
      let bodyFix = []
      if( Array.isArray(body) ) {
        body.forEach((item) => {
          // console.log('item', item)
          if (Array.isArray(item)) {
            let bodyFix2 = []
            item.forEach((item2) => {
              // console.log('item2', item2)
              if (Array.isArray(item2)) {
                let ary = item2.flat()
                bodyFix2 = bodyFix2.concat(ary)
                // console.log(ary)
              } else
                bodyFix2.push(item2)
            })
            bodyFix.push(bodyFix2)
          } else
            bodyFix.push(item)
        })
      }
      // console.log(bodyFix)
      items = bodyFix
    } else {
      items = []
    }
  } else {
    items = props.items
  }
  if( items === null || items === undefined )
    items = []
  
  const isSearchFragment = props.isSearchFragment
  const others = props.others
  let multi = {
    tableHeadCell: [],
    tableBodyCell: [],
  }
  if(isSearchFragment) {
    let texts = props.texts
    if( Object.isObject(texts) && texts.multi !== undefined )
      multi = texts.multi
  }
  
  // console.log('items', items)
  function getArray1D(ary) {
    let ary1d = []
    if (ary !== undefined) {
      ary.forEach((item) => {
        if (Array.isArray(item))
          return (item.forEach((i) => (ary1d.push(i[0]))))
        else
          return ary1d.push(item)
      })
    }
    return ary1d
  }
  
  //---------------------------------------------------------------------------
  // search
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.handleClickSearch();
    }
  }
  
  //---------------------------------------------------------------------------
  // per page menu
  let texts = {
    menus: [
      'Show up to',
    ],
  }
  const itemsPerPageList = [10, 50, 100,]
  itemsPerPageList.forEach((number) => (texts.menus.push(`${number} items`)))
  // console.log(texts.menus);
  
  const [menuAnchorEl, setAnchorEl] = React.useState(null);
  const [menuSelectedIndex, setSelectedIndex] = React.useState(1);

  const menuOpen = Boolean(menuAnchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClickMenuItem = (event, index) => {
    // console.log('index', index);
    switch (index) {
      case 3:
      case 2:
        props.eventChangeItemsPerPage(itemsPerPageList[index - 1])
        break
      default:
      case 1:
        // case 0: //disable
        props.eventChangeItemsPerPage(itemsPerPageList[0])
        break
    }

    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  
  //---------------------------------------------------------------------------
  // page button
  function newPageButton(item, index) {
    let style = { background: scss.unselectBg }
    if (item === String(props.pageNo.now))
      style = { background: scss.selectBg }
    if (item === String(props.words.page[1])) //...
    {
      style = { cursor: 'default', border: 0 }
    }
    return (
      <div className={scss.bar_page}
        style={style}
        key={index}
        value={item}
        onClick={(event) => { props.handleClickPage(item) }}>
        <Text center className='b2strong-darkGreen'>{item}</Text>
      </div>
    )
  }
  
  const [goToValue, setGoToValue] = React.useState('');
  const onInputChange = (event) => {
    // Replace any non-digit characters with an empty string
    const newValue = event.target.value.replace(/\D/g, '');

    // Update the input's value
    // event.target.value = newValue;
    setGoToValue(newValue)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.handleClickPage(event.target.value)
    }
  }
  
  //---------------------------------------------------------------------------
  const TableBodyCell = (props) => {
    // console.log('props', props);
    // console.log('tableBodyCell', props.tableBodyCell);
    const { tableBodyCell, children, inputProp, index } = props
    const isProtein = (tableBodyCell === spp.protein.search.quick.output)
      || (tableBodyCell === spp.protein.search.classify.output)
      || (tableBodyCell === spp.protein.search.source.output)
      || (tableBodyCell === spp.protein.search.fragment_count.output)
      || (tableBodyCell === spp.protein.search.classify_count.output)
    //|| (tableBodyCell === spp.protein.search.peptide_list.output)
    
    const isCount = (tableBodyCell === spp.protein.search.fragment_count.output)
      || (tableBodyCell === spp.protein.search.classify_count.output)

    const isPeptide = (tableBodyCell === spp.peptide.search.advanced.output)
      || (tableBodyCell === spp.peptide.search.classify.output)
      || (tableBodyCell === spp.peptide.search.classify.output_ic50)
      || (tableBodyCell === spp.peptide.search.multi.output)
      || (tableBodyCell === spp.peptide.search.quick_classify.output)
      || (tableBodyCell === spp.peptide.search.inductive_analysis.items)
      || (tableBodyCell === spp.peptide.search.inductive_analysis.items_ic50)
      || (tableBodyCell === spp.peptide.search.classification_advanced_search.output)
      || (tableBodyCell === spp.tool.crs_tool_result.items)
      || (tableBodyCell === spp.tool.crs_tool_result.items_multi)
      
    const isPeptideMulti = (tableBodyCell === spp.peptide.search.multi.output)
      || (tableBodyCell === spp.tool.crs_tool_result.items_multi)
      || (tableBodyCell === spp.peptide.search.advanced_multi.output)

    let link, classJson = ''
    if (isProtein) {
      link = paths.spp.protein.id_result()
    } else if (isPeptide) {
      switch (tableBodyCell) {
        case spp.peptide.search.classify.output:
        case spp.peptide.search.classify.output_ic50:
          classJson = inputProp.toString() //[classifyName]
          break
        case spp.peptide.search.quick_classify.output: //[queryClass, filterName, filterValue]
        case spp.peptide.search.classification_advanced_search.output: //[classification, filterSelection, filterValue]
        case spp.peptide.search.inductive_analysis.items: //[queryClass, keyword]
        case spp.peptide.search.inductive_analysis.items_ic50: //[queryClass, keyword]
          classJson = inputProp[0].toString()
          break
        case spp.tool.crs_tool_result.items:
          classJson = children[spp.common.output.table_name]
          break
        default:
          break
      }
    } else if (isPDCAASTable) {
      link = paths.spp.tool.pdcaas()
    }

    if (isCount) {
      if (Array.isArray(children)) {
        return children.map((value, index) => {
          if (isProtein && index === 0) { //'SPP ID'
            return (
              <MuiTableCell key={value}>
                <RouterLink newTab to={paths.spp.protein.id_result(value)}>
                  {value}</RouterLink>
              </MuiTableCell>)
          } else if (isProtein && index === children.length - 1) { // Last one is 'UniProtKB'
            return (
              <MuiTableCell key={`${value}_${index}`}>
                {links.outside.UniprotKB(value)}
              </MuiTableCell>)
          } else {
            return (<MuiTableCell key={`${value}_${index}`}><TagText html={value} /></MuiTableCell>)
          }
        })
      } else {
        return (<MuiTableCell key={children}><TagText html={children} /></MuiTableCell>)
      }
    } else {
      return (
        tableBodyCell.map((cell, indexX) => {
          // console.log(cell);
          if (isProtein || isToolManualPeptide) {
            if (cell === 'SPP_ID') {
              return (
                <MuiTableCell key={indexX}>
                  <RouterLink newTab to={paths.spp.protein.id_result(children[cell])}>
                    {children[cell]}</RouterLink>
                </MuiTableCell>)
            } else if (cell === 'uni_prot_KB') {
              return (
                <MuiTableCell key={indexX}>
                  {links.outside.UniprotKB(children[cell])}
                </MuiTableCell>)
            }
          } else if (isPeptide || isPeptideMulti) {
            if (cell === 'SPP_ID') {
              return (
                <MuiTableCell key={indexX}>
                  {isPeptideMulti
                  ? <RouterLink newTab to={paths.spp.peptide.id_multi_result(children[cell])}>
                      {children[cell]}</RouterLink>
                  : classJson !== ''
                    ? <RouterLink newTab to={paths.spp.peptide.id_result(children[cell], classJson)}>
                        {children[cell]}</RouterLink>
                    : children[cell]
                  }
                </MuiTableCell>)
            } else if (cell === "peptide_function_name"
              || cell === "IC50"
              || cell === "peptide_mass") { //spp.peptide.search.classify.output_ic50              
              return (
                <MuiTableCell key={indexX}>
                  <TagText html={children[cell].trimHTML()} />
                </MuiTableCell>)
            } else if (cell === 'structure') {
              let seq = children['AA_sequence']
              link = paths.spp.peptide.molstar(seq, '0')
              return (
                <MuiTableCell key={indexX} align={'center'}>
                  <div style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    // backgroundColor: '#ff0', //debug
                  }}>
                    <iframe
                      src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${seq}&ctrl=0`}
                      width="160px"
                      height="80px" //40px + height(molstart index.html #app)
                      style={{ border: 'none' }}
                    />
                    <div style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 1, //overlap on iframe
                          backgroundColor: 'rgba(255, 255, 255, 0)', //Transparent background 
                          // backgroundColor: '#f00', //debug
                          cursor: 'pointer',
                      }}
                      // onClick={() => navigateNewTab(paths.spp.peptide.molstar(seq))}
                      onClick={() => handleClickOpen(seq)}
                    />
                  </div>
                </MuiTableCell>)
            }
          } else if (isPDCAASTable) {
            if (cell === 'more') {
              return (
                <MuiTableCell key={indexX}>
                  <RouterLink newTab to={link} onClick={() => {
                    let num = children["num"]
                    // console.log(num, children["Description"]);
                    cookie.setCookie(cookie.keys.tool.pdcaasSelectOrganism, num)
                    cookie.setCookie(cookie.keys.tool.pdcaasSelectWeight, "")
                  }}>more</RouterLink>
                </MuiTableCell>)
            } else if (cell === "Digestibility") {
              let val = children[cell] * 100
              return (
                <MuiTableCell key={indexX}>
                  <TagText html={`${val}%`} />
                </MuiTableCell>)
            }
          }
          return (
            <MuiTableCell key={indexX} align={getCellAlign(cell, indexX)}>
              <TagText html={(children[cell])} />
            </MuiTableCell>)
        }))
    }
  }
  
  function getCellAlign(cell, index) {
    return 'left'
  }
  
  //------------------------------------------------------------------------------
  const [openMolstar, setOpenMolstar] = React.useState(false);
  const [peptideSeq, setPeptideSeq] = useState('')

  const handleClickOpen = (seq) => {
    setPeptideSeq(seq)
    setOpenMolstar(true);
  };

  const handleClose = () => {
    setOpenMolstar(false);
    setPeptideSeq('')
  };
  
  const DialogMolstar = (props) => {
    const { seq } = props
    // console.log('seq', seq);
    return (
      <Dialog
        fullWidth={true}
        maxWidth={false}
        // fullScreen={true}
        open={openMolstar}
        onClose={handleClose}
      >
        <DialogContent style={{ height: '90vh' }}>
          <iframe
            src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${seq}`}
            width="100%"
            height="100%" //40px + height(molstart index.html #app)
            title="MolstarViewer"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    )
  }
  
  //---------------------------------------------------------------------------
  return (
    <>
      <div className={scss.frame_search}>
        {isCount || isHideSearchBar
          ? <div></div>
          : (isPeptideList)
            ? (<h4 className={"color-white"}>{props.texts.searchHint}</h4>)
            : (
            <div className={scss.search_text}>
              <InputBase className='b2-grey100'
                sx={{ m: 0, flex: 1 }}
                placeholder={props.texts.searchHint}
                value={props.keyword}
                onChange={(event) => { props.handleChangeSearch(event.target.value) }}
                onKeyDown={handleKeyDown}
              />
              <IconButton type="button" sx={{ padding: '0px' }} aria-label="search" onClick={props.handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </div>)
        }
        <div className={scss.search_index}>

          <Text center className='b1strong-darkGreen'>
            <TagText html={props.words.index[0]} /></Text>
          <Text center className='b1-grey100'>
            <TagText html={props.words.index[1]} /></Text>

          {/* <IconButton type="button" sx={{ padding: '0px' }} aria-label="items"
            id='show-up-button'
            aria-controls={menuOpen ? 'show-up-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={handleClickMenu}>
            <KeyboardArrowDownIcon />
          </IconButton>
          <StyledMenu
            id="show-up-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'show-up-menu',
            }}
          >
            {texts.menus.map((item, index) => (
              <MenuItem key={index}
                disabled={index === 0}
                selected={index === menuSelectedIndex}
                onClick={(event) => handleClickMenuItem(event, index)}
              >{item}</MenuItem>
            ))}
          </StyledMenu> */}
        </div>
      </div>

      <div className={scss.frame_table}>
        <TableContainer component={Paper} variant="outlined" className={scssCommon.table_container}>
          {props.isLoading ? <LoadingAnime /> :
            items.length === 0
            ? <Table sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 2 }}>
                No matching data found</Table>
            : <Table aria-label="a dense table">
                <TableHeadRow className="bgc-dark-green">
                  {tableHeadCell.map((cell, index) => (
                    <MuiTableCell key={index} align={getCellAlign(cell, index)}>
                      <TagText html={cell} /></MuiTableCell>
                  ))}
                </TableHeadRow>
                <TableBody>
                  {items.map((item, index) => (
                    <TableRow2Body key={index}>
                      <MuiTableCell component="th" scope="row">
                        {index + props.itemNo.start}</MuiTableCell>
                      <TableBodyCell tableBodyCell={props.tableBodyCell} inputProp={props.inputProp} index={index}>
                        {item}</TableBodyCell>
                    </TableRow2Body>
                  ))}
                </TableBody>
              </Table>}
        </TableContainer>
      </div>
      
      {isSearchFragment && others.length > 0
      ? <>
        <div className={scss.frame_table}>
            <TableContainer component={Paper} variant="outlined" className={scssCommon.table_container}>
              {props.isLoading ? <LoadingAnime /> :
              <Table aria-label="a dense table">
                <TableHeadRow className="bgc-dark-green">
                  {multi.tableHeadCell.map((cell, index) => (
                    <MuiTableCell key={index}>{cell}</MuiTableCell>
                  ))}
                </TableHeadRow>
                <TableBody>
                  {others.map((item, index) => (
                    <TableRow2Body key={index}>
                      <MuiTableCell component="th" scope="row">{index + props.itemNo.start + props.output.total}</MuiTableCell>
                      <TableBodyCell tableBodyCell={multi.tableBodyCell} inputProp={props.inputProp} index={index}>{item}</TableBodyCell>
                    </TableRow2Body>
                  ))}
                </TableBody>
              </Table>}
            </TableContainer>
          </div>
        </>
      : null }
      {openMolstar
      ? <DialogMolstar seq={peptideSeq} />
      : null}
      
      {isHidePageButton ? null
      : <div className={scss.frame_bottom}>
          <div className={scss.bottom_bar}>
            {props.pages.map((item, index) => (newPageButton(item, index)))}
          </div>

          <div className={scss.divider} />

          <div className={scss.go_to_page_block} title="Input page number">
            <input className={scss.go_to_page_input} id="go-to-page-input" type="number" placeholder="#" onChange={onInputChange} onKeyDown={handleKeyPress} value={goToValue} />
          </div>

          <div className={scss.go_to_btn}
            onClick={() => {
              props.handleClickPage(goToValue)
            }}
          >
            {props.words.goto}
          </div>
        </div>}
    </>)
}
export default SearchResultTable